import React, { FunctionComponent, useState, useRef, ChangeEvent } from "react";

interface IProps {
  finished : boolean
  scores: string[]
}

// markup
const ScoresContainer : FunctionComponent<IProps> = ({
  finished,
  scores
}) => {

    let max : number = finished ? Math.max(...scores.map(s => parseInt(s))) : 1;
    
    // console.log(finished);
    // console.log(max);

    return (
        <div id={"scores-container"}>
            <h2>Scores</h2>
            <div>
            {scores.map((d, i) => (
                <div className="score-container">
                    <div className="score-index">{i + 1}</div>
                    <div id={"score-indicator-" + i} className="score-indicator">{d}</div>
                </div>
                ))
            } 
            </div>
        </div>
    )
}

export default ScoresContainer